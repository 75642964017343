/*!
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

@import 'variables.scss';
@import 'themes.scss';

html {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  font-size: var(--msi-ui-primary-font-size);
}

body {
  display: flex;
  flex: 1;
  background: var(--background-level-1);
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

:root {
  --notification-box-shadow: 0 1px 3px 0 #0000004D;
}

@mixin app-light-theme() {
  @include light-theme;
  &:where(:root, body) {
    --background-level-1: #EEF6F9;
  }
  --primary: #{$msi-ui-primary-highlight};
  --secondary: #{$msi-ui-secondary-highlight};
  --background-secondary: #{$msi-ui-primary-light};
  --card-background: var(--background-level-3);
  --card-background-secondary: #{$msi-ui-primary-dark-05};
  --text-color: #{$msi-ui-primary-dark-90};
  --text-color-secondary: #{$msi-ui-primary-light};
  --text-color-dim: #{$msi-ui-primary-dark-65};
  --border-primary: 1px solid var(--stroke-level-1);
  --border-primary-thick: 5px solid #{$msi-ui-primary-dark-10};
  --border-secondary: 1px solid var(--stroke-level-2);
  --border-radius: $msi-ui-corner-radius-md;
  --box-shadow: var(--shadow-level-1);
  --map-bar: #{$msi-ui-background-bright};
  --empty-queue: lightgreen;
  --selected-call: rgba(0, 122, 170, 0.2);
  --ringing-acd-call: #077B3A;
  --ringing-non-acd-call: #03361A;
  --invite-user-icon: #ffdcdc;
  --ui-alert: #{$msi-ui-alert};
  --tag-background: #{$msi-ui-primary-dark-50};
  --tag-text-color: #{$msi-ui-primary-light};
  --msi-ui-spacing-xs: #{$msi-ui-spacing-xs};
  --msi-ui-spacing-s: #{$msi-ui-spacing-s};
  --msi-ui-spacing-m: #{$msi-ui-spacing-m};
  --msi-ui-spacing-l: #{$msi-ui-spacing-l};
  --msi-ui-spacing-xl: #{$msi-ui-spacing-xl};
  --msi-ui-spacing-xxl: #{$msi-ui-spacing-xxl};
  --msi-ui-input-padding: #{$msi-ui-input-padding};
  --msi-mission-critical-h1-font: bold 32px/37px "Public Sans";
  --msi-mission-critical-h3-font: bold 24px/36px "Public Sans";
  --msi-mission-critical-h4-font: bold 20px/30px "Public Sans";
  --msi-mission-critical-h5-font: bold 16px/24px "Public Sans";
  --msi-mission-critical-h6-font: bold 16px/24px "Public Sans";
  --msi-mission-critical-body-1-font: #{$font-weight-semibold}  20px/30px "Public Sans";
  --msi-mission-critical-body-2-font: 16px/24px "Public Sans";
  --msi-mission-critical-speed-dial-font: bold 16px/20px "Public Sans";
  --msi-mission-critical-monospace-date-time-font: normal 24px/30px;
  --sms-text-background: #{$msi-ui-neutral};
  --inbound-message-background: #{$msi-ui-primary-light};
  --outbound-message-background: #{$msi-ui-background-bright};
  --table-background: #{$table-background};
  --msi-ui-widget: #fffffff9;
  --call-card-header-background-color: var(--background-level-1);
  .esri-ui .msi-dropdown msi-dropdown-item, .msi-label {
    color: #{$msi-ui-primary-light} !important;
  }
  --msi-directory-button-filter: unset;
  --msi-directory-button-fill-opacity: 0.1;
  --msi-btn-border-color: var(--msi-ui-primary-dark-70);
  --msi-status-default-color: var(--msi-ui-primary-dark-70);
  --msi-intervention-banner-background-color: var(--msi-ui-primary-dark-20);
  --rounded-btn-bg: var(--msi-ui-primary-dark-05);
  --rounded-btn-bg-hover: var(--msi-ui-primary-dark-10);
  --rounded-btn-border: var(--msi-ui-primary-dark-65);
  --rounded-btn-active-focus-border: var(--msi-ui-primary-light-65);
  --btn-alert-hover: #FF5F5F;
  --tooltip-background-color-custom: var(--tooltip-background);
  --tab-border-color: var(--msi-ui-primary-dark-40);
  --status-toggle-go-not-ready: var(--msi-ui-primary-dark-20);
  --card-divider-color: var(--msi-ui-primary-dark-40);
  --notification-background: #F2F2F2;
}

@mixin app-dark-theme() {
  @include dark-theme;

  --primary: #{$msi-ui-secondary-highlight};
  --secondary: #{$msi-ui-secondary-highlight};
  --background-secondary: #{$msi-ui-secondary};
  --card-background: var(--background-level-3);
  --card-background-secondary: #{$msi-ui-primary-light-05};
  --text-color: #{$msi-ui-primary-light};
  --text-color-secondary: #{$msi-ui-primary-dark};
  --text-color-dim: #{$msi-ui-primary-light-65};
  --border-primary: 1px solid var(--stroke-level-1);
  --border-primary-thick: 5px solid #{$msi-ui-primary-light-10};
  --border-secondary: 1px solid var(--stroke-level-2);
  --border-radius: $msi-ui-corner-radius-md;
  --box-shadow: var(--shadow-level-1);
  --map-bar: #{$msi-ui-background-dark};
  --empty-queue: darkgreen;
  --selected-call: rgba(0, 122, 170, 0.4);
  --ringing-acd-call: #077B3A;
  --ringing-non-acd-call: #03361A;
  --invite-user-icon: maroon;
  --ui-alert: #{$msi-ui-alert-secondary};
  --tag-background: #{$msi-ui-primary-light};
  --tag-text-color: #{$msi-ui-primary-dark};
  --msi-ui-spacing-xs: #{$msi-ui-spacing-xs};
  --msi-ui-spacing-s: #{$msi-ui-spacing-s};
  --msi-ui-spacing-m: #{$msi-ui-spacing-m};
  --msi-ui-spacing-l: #{$msi-ui-spacing-l};
  --msi-ui-spacing-xl: #{$msi-ui-spacing-xl};
  --msi-ui-spacing-xxl: #{$msi-ui-spacing-xxl};
  --msi-ui-input-padding: #{$msi-ui-input-padding};
  --msi-mission-critical-h1-font: bold 32px/37px "Public Sans";
  --msi-mission-critical-h3-font: bold 24px/36px "Public Sans";
  --msi-mission-critical-h4-font: bold 20px/30px "Public Sans";
  --msi-mission-critical-h5-font: bold 16px/24px "Public Sans";
  --msi-mission-critical-h6-font: bold 16px/24px "Public Sans";
  --msi-mission-critical-body-1-font: #{$font-weight-semibold} 20px/30px "Public Sans";
  --msi-mission-critical-body-2-font: 16px/24px "Public Sans";
  --msi-mission-critical-speed-dial-font: bold 16px/20px "Public Sans";
  --msi-mission-critical-monospace-date-time-font: normal 24px/30px;
  --sms-text-background: #{$msi-ui-neutral};
  --inbound-message-background: #{$msi-ui-secondary};
  --outbound-message-background: #{$msi-ui-primary-highlight-50};
  --table-background: #{$table-background};
  --msi-ui-widget: #{$msi-ui-widget};
  --call-card-header-background-color: #10374E;

  --msi-supervisor-background-main: #{$msi-ui-primary-dark-50};
  --msi-supervisor-card-body: #{$msi-ui-mhx};
  --msi-supervisor-card-heading: #{$msi-ui-neutral-highlight};
  --msi-directory-button-filter: brightness(1.25) contrast(0.7);
  --msi-directory-button-fill-opacity: 0.2;
  --msi-btn-border-color: var(--msi-ui-primary-light-70);
  --msi-status-default-color: var(--msi-ui-primary-light-40);
  --msi-grouped-tab: var(--msi-ui-primary-highlight-30);
  --msi-intervention-banner-background-color: var(--msi-ui-primary-light-20);
  --rounded-btn-bg: var(--msi-ui-primary-light-10);
  --rounded-btn-bg-hover: var(--msi-ui-primary-light-20);
  --rounded-btn-border: var(--msi-ui-primary-light-70);
  --rounded-btn-active-focus-border: var(--msi-ui-primary-dark-90);
  --btn-alert-hover: #FF5F5F;
  --tooltip-background-color-custom: var(--msi-ui-neutral-dark);
  --tab-border-color: var(--msi-ui-primary-light-40);
  --status-toggle-go-not-ready: var(--msi-ui-primary-light-20);
  --card-divider-color: var(--msi-ui-primary-light-40);
  --notification-background: var(--tooltip-background);
}

html.dark {
  @include app-dark-theme;
}

html.light {
  @include app-light-theme;
}

// Apollo Mission Critical Cobalt global overrides

msi-accordion.flex-accordion msi-accordion-item .msi-accordion-item {
  width: unset;
  min-width: unset;
  padding: 0;
}

msi-accordion.flex-accordion > msi-accordion-item > .msi-collapsable > div {
  padding: 0;
}

.msi-tag.h5-tag {
  margin: 0;
  height: 32px;
}

.msi-tag.h5-tag a {
  font: var(--msi-mission-critical-h5-font);
  border-width: 2px;
}

.msi-tag.clamp-1,
.msi-tag.clamp-3 {
  width: 100%;
  overflow: hidden;
}

/* -webkit-line-clamp must be used in conjunction with display: -webkit-inline-box; and -webkit-box-orient: vertical;
  and allows us to word wrap AND ellipsis text overflow */
.msi-tag.clamp-1 a,
.msi-tag.clamp-3 a {
  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.msi-tag.clamp-1 a {
  -webkit-line-clamp: 1;
}

.msi-tag.clamp-3 a {
  -webkit-line-clamp: 3;
}

.msi-tag a {
  color: unset;
  width: max-content;
  align-items: center;
  font: var(--msi-mission-critical-h5-font);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 3px 8px;
}

.msi-tag.actionable a {
  padding: 6px 12px;
}

.msi-tag.actionable a .msi-icon {
  height: 17px;
  width: 17px;
}

.msi-slider-native[orient='vertical'] {
  width: 25px !important;
}

.msi-tab-labels {
  border-bottom: 1px solid var(--tab-border-color);
  font: var(--msi-mission-critical-h4-font);
}

.msi-tab-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;
  min-height: 0;
  overflow: auto;
}

//.msi-btn:focus::before,
//.msi-btn:active::before {
//  border: none !important;
//}

.msi-btn.msi-btn-action:focus:hover {
  color: white;
  background: var(--button-background) !important;
}

.msi-btn.msi-btn-action .msi-icon {
  color: inherit;
}

.btn-icon {
  padding-right: 12px;
}

msi-elapsed-time,
.font-mono {
  font-feature-settings: $monospace;
}

.msi-modal-host {
  margin: 0;
}

.msi-btn,
.msi-btn.msi-btn-secondary {
  height: 42px;
  padding: 6px 12px;
  font: var(--msi-mission-critical-h5-font);
}

.msi-input {
  font: var(--msi-mission-critical-body-2-font);
  padding: 9px 12px;
}

.msi-input:not(.msi-chars-counter-container) {
  height: 42px;
}

.msi-input-group .msi-input-button .msi-icon {
  bottom: 8px;
  right: 6px;
  padding: 0;
  width: 24px;
  height: 24px;
}

.msi-input-label {
  font: var(--msi-mission-critical-h5-font);
  padding-bottom: var(--msi-ui-spacing-xs);
}

.msi-textarea {
  font: var(--msi-mission-critical-body-2-font);
}

.msi-chars-counter-container .msi-counter {
  height: 31px;
  padding-right: 18px;
  color: var(--primary);
  font: var(--msi-mission-critical-body-2-font);
}

.msi-input-group .msi-input-button .msi-icon[disabled] {
  opacity: unset;
}

.msi-dropdown.announcements-widget,
.msi-dropdown.dev-widget,
.msi-dropdown.connection-widget,
.msi-dropdown.dial-pad-widget {
  max-height: unset;
}

.msi-dropdown.announcements-widget,
.msi-dropdown.connection-widget,
.msi-dropdown:has(msi-message-predefined-message-widget),
.msi-dropdown.dev-widget,
.msi-dropdown.language-widget,
.msi-dropdown.dial-pad-widget {
  padding: 0;
}

.msi-dropdown:has(msi-message-predefined-message-widget) {
  max-height: unset;
  border-radius: 10px;
}

msi-tab-group div.msi-tab-labels button.msi-tab-label {
  height: 58px;
  padding: 12px 18px;

  &::before {
    border-bottom-width: 4px !important;
  }
}

.msi-toast-container {
  .msi-toast {
    .msi-toast-body {
      .msi-toast-message {
        font: var(--msi-mission-critical-body-2-font);
        padding: 12px 10px;
      }

      padding-right: 5px;

      .msi-toast-icon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px;
        gap: 10px;

        msi-icon {
          width: 24px !important;
          height: 24px !important;

          i {
            margin: 0;
          }
        }
      }

      .msi-toast-close-button {
        padding: 0;

        msi-icon {
          width: 36px !important;
          height: 36px !important;

          i {
            color: var(--text-primary);
            margin: 0;
          }
        }
      }
    }

    &.msi-toast--success {
      .msi-toast-icon .msi-icon {
        color: var(--msi-ui-primary-dark-80) !important;
      }
    }
    &.msi-toast--error {
      .msi-toast-icon .msi-icon {
        color: var(--text-color-secondary) !important;
      }
    }
  }
}

.msi-toggle-label {
  font: var(--msi-mission-critical-h5-font);
  padding: 0;
}

.clickable {
  cursor: pointer;
}

.msi-button-group button.msi-btn.msi-btn-secondary.msi-btn-rounded {
  border-radius: 0;
}

msi-button-group.msi-button-group button.msi-btn.msi-btn-secondary.msi-btn-rounded:first-of-type {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  min-width: 0;
}

msi-button-group.msi-button-group button.msi-btn.msi-btn-secondary.msi-btn-rounded:last-of-type {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  min-width: 0;
}

msi-button-group.msi-button-group {
  box-shadow: none;
}

button.msi-btn.msi-btn-secondary.msi-btn-rounded {
  border-radius: 100px;
  background-color: var(--rounded-btn-bg);
  font: var(--msi-mission-critical-h5-font);
  border: 2px solid var(--rounded-btn-border);
  padding: 12px 18px;
  height: 48px;
  gap: var(--msi-ui-spacing-xs);
  min-width: 150px;
  color: var(--text-primary);

  &.icon-btn {
    padding: 6px 12px;
    min-width: 0;
  }

  &.alert {
    border-color: transparent;
    background-color: var(--ui-alert);
    color: var(--text-color-secondary) !important;

    .msi-icon {
      color: inherit;
    }

    &:hover:not(:disabled, :active) {
      background-color: var(--btn-alert-hover);
    }

    &:focus {
      background-color: var(--btn-alert-hover);

      &:before {
        border-radius: inherit;
        border-color: var(--btn-alert-hover);
      }
    }
  }

  msi-icon {
    color: inherit;
  }

  &:hover:not(:disabled, :active) {
    background-color: var(--rounded-btn-bg-hover);

    &.pressed {
      background: var(--button-hover);
    }
  }

  &:focus::before {
    top: -6px;
    bottom: -6px;
    left: -6px;
    right: -6px;
  }

  &:focus {
    &:before {
      border-radius: inherit;
    }
  }

  &.pressed {
    color: var(--text-primary);
    background: var(--button-pressed-hover);
    border-color: var(--button-pressed-hover);
  }
}

msi-sidebar-top {
  align-items: center;
}

msi-sidebar-item {
  width: 72px;
  height: 72px;
}

.sidebar-container {
  .description-container {
    display: none !important;
  }

  &:nth-last-child(1) {
    padding: 0;
  }

  &:nth-child(1) {
    padding: 0;
  }

  .msi-sidebar-item {
    margin: 0;
  }
}

.sidebar-container .msi-sidebar-item .icon-container,
.sidebar-container .sidebar-item-expand .icon-container {
  position: relative;
  margin: auto;
  width: 60px;
  height: 60px;
  border-radius:3px;

  .msi-icon {
    height: 36px;
    width: 36px;

    i {
      margin: unset;
    }
  }
}

.icon-trigger {
  cursor: pointer;
  padding: 5px;

  &.msi-dropdown-trigger-open {
    color: var(--msi-ui-primary-light);
    background: var(--focus-primary);
  }
}

.msi-table {
  th, td {
    height: 48px;
  }
  tr {
    .msi-col-type-text .msi-header-text .drag-grid {
      padding: unset;
    }
  }
  td {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 12px;
    &.white-space-break {
      white-space: break-spaces;
    }
  }
  th {
    &.msi-table-header .msi-table-header-wrapper .msi-table-header-left .msi-header-text {
      padding: 0 12px;
    }
    .msi-checkbox {
      justify-content: center;
    }
  }

  &.threshold-table {
    border-collapse: separate;
    border-spacing: 1px;
  }

  & thead {
    z-index: 99 !important;
  }

  .sticky-action-col {
    position: sticky;
    left: 0;
    box-shadow: -1px 0 0 var(--primary-40) inset;
    z-index: 9;
    padding-left: 4px;
    background: var(--background-level-1);

    .sticky-col-items {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  .priority-sort-col {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 23px;
  }
}

// Thresholds
.threshold-low {
  border: 3px solid var(--msi-ui-ptt-listen) !important;
}

.threshold-medium {
  border: 3px solid var(--msi-ui-ptt-listen) !important;
  background-color: var(--msi-ui-ptt-listen);
  color: var(--msi-ui-primary-dark-90);
}

.threshold-high {
  border: 3px solid var(--ui-alert) !important;
  background-color: var(--ui-alert);
  color: var(--msi-ui-primary-dark-90);
}

.threshold-header-icon {
  margin-left: auto;
}

.threshold-medium {
  position: relative;

  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 3px solid black;
  }
}

tr.threshold-high, tr.threshold-medium, tr.threshold-low {
  border-radius: 4px;
}

tr.threshold-high, tr.threshold-medium {
  color: black;

  td {
    .msi-btn-action {
      background-color: var(--msi-ui-primary-light) !important;
      color: black !important;
    }
  }
}

.threshold-container {
  display: flex;
  align-items: center;
}

.msi-tooltip, .msi-tooltip .msi-tooltip-content {
  width: auto;
  max-width: unset;
}

.msi-tooltip .msi-tooltip-content {
  background-color: var(--tooltip-background-color-custom);
}

.msi-card, .msi-card:hover {
  box-shadow: none;
  border: none;
}

.checkbox-xl {
  .msi-checkbox-icon {
    width: 27px;
    height: 27px;

    i {
      width: 27px;
    }
  }
}

.full-width-tabs {
  .cdk-drag {
    flex: 1;

    .msi-tab-label {
      width: 100%;
    }
  }
}

.msi-select {
  height: 42px;
  .msi-select-origin {
    height: 42px;
  }
}

.msi-select-options-list {
  msi-select-option {
    height: auto;
    padding: 9px 24px 9px 18px;
  }
}

.msi-sidebar {
  z-index: 99996;
}

#initialization-error {
  display: none;
  font: var(--msi-mission-critical-h3-font);
  color: var(--text-color);
  background: var(--background-level-1);
  #auth-error-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 606px;
    svg {
      color: #28B1EC;
      width: 186px;
      height: 186px;
      flex: none;
    }
    #auth-error-inner-wrapper {
      display: flex;
      align-items: center;
      gap: 15px;
    }
    #auth-error-text {
      text-align: left;
      display: flex;
      flex-direction: column;
      line-height: 30px;
      #auth-error-title {
        font-size: 48px;
        font-weight: 600;
        margin-bottom: 30px;
      }
      p {
        font-size: 24px;
      }
    }
  }
  .fail-recovery {
    margin-top: 20px;
    align-self: baseline;
  }
}

#initialization-error.show {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.speed-dial-tooltip-divider {
  width: 100%;
  border:1px solid var(--stroke-level-2);
}

.speed-dial-tooltip-wrapper {
  // Speed dial width minus tooltip padding
  width: 144px;
}
